.container {
    gap: 10px;
    top: -25px;
    width: 100%;
    height: 25px;
    display: none;
    z-index: 5000;
    position: absolute;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.resizeIndicator {
    width: 48px;
    height: 5px;
    cursor: pointer;
    border-radius: 3px;
    background-blend-mode: overlay, luminosity;
    background: linear-gradient(0deg, #001529 0%, #001529 100%), rgba(0, 21, 41, 0.40);
}

@media (max-width: 991px) {
    .container {
        display: flex;
    }
}