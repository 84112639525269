.body {
    display: flex;
    margin-top: 1.2rem;

    & > span {
        font-size: 1.6rem;
        line-height: 2.4rem;
    }
}

.footer {
    display: flex;
    padding: 1.6rem;
    border-radius: 8px;
    margin-top: 2.8rem;
    background: #F5F5F5;
    align-items: center;
    justify-content: space-between;
}