.card {
    gap: 0.8rem;
    display: flex;
    padding: 1.6rem;
    border-radius: 0.8rem;
    margin-bottom: 3.2rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border: 1px solid rgba(255, 255, 255, 0.05);
    background-color: rgba(255, 255, 255, 0.10);
}
