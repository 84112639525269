.card {
    gap: 16px;
    display: flex;
    padding: 16px;
    border-radius: 8px;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.05);
}

.text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.88);
}

.formItem {
    gap: 8px;
    display: flex;
    flex-direction: column;
}

.button:disabled {
    color: #FFFFFF40 !important;
    background: rgba(255, 255, 255, 0.04) !important;
    border: 1px solid rgba(255, 255, 255, 0.15) !important;
}